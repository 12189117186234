import "core-js/modules/es.error.cause.js";
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withKeys as _withKeys, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7e11b230"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "edit-password"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "action-btn"
};
import PageTitle from '@/components/page-title';
import { editPassword } from '@/service/setting/password.js';
import { reactive, ref } from 'vue';
import { ElMessage } from 'element-plus';
import useUserStore from '@/store/modules/user.js';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'edit-password',

  setup(__props) {
    const {
      t
    } = useI18n();
    const userStore = useUserStore();
    const formRef = ref(null);
    const passForm = reactive({
      oldPass: '',
      newPass: '',
      checkPass: ''
    });

    const handleSavePassword = () => {
      const data = {
        old_password: passForm.oldPass,
        new_password: passForm.newPass
      };
      editPassword(data).then(res => {
        if (res.code == 200) {
          // 修改密码成功，请重新登录
          ElMessage({
            message: t('password.save_success'),
            type: 'success'
          });
          formRef.value.resetFields();
          userStore.initUserExit(); // 强制退出
        } else {
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    };

    const handleSavePasswordClick = () => {
      var _formRef$value;

      (_formRef$value = formRef.value) === null || _formRef$value === void 0 ? void 0 : _formRef$value.validate(valid => {
        if (valid) {
          // 1. 注册请求
          handleSavePassword();
        }
      });
    };

    const validatePass = (rule, value, callback) => {
      if (value.length < 5) {
        callback(new Error(t('password.form_rules.new_password.length')));
      } else {
        callback();
      }
    };

    const validatePass2 = (rule, value, callback) => {
      if (value != passForm.newPass) {
        callback(new Error(t('password.form_rules.confirm_password.no_equal')));
      } else {
        callback();
      }
    };

    const formRule = {
      oldPass: [{
        required: true,
        message: t('password.form_rules.curr_password.required'),
        trigger: ['blur', 'change']
      }],
      newPass: [{
        required: true,
        message: t('password.form_rules.new_password.required'),
        trigger: ['blur', 'change']
      }, {
        validator: validatePass,
        trigger: ['blur']
      }],
      checkPass: [{
        required: true,
        message: t('password.form_rules.confirm_password.required'),
        trigger: ['blur', 'change']
      }, {
        validator: validatePass2,
        trigger: ['blur']
      }]
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(PageTitle), {
        title: _ctx.$t('settingMain.menu_list.edit_password')
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        model: passForm,
        "label-position": "top",
        "label-width": "100px",
        "hide-required-asterisk": "",
        style: {
          "width": "300px"
        },
        rules: formRule,
        size: "large"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: _ctx.$t('password.curr_password'),
          prop: "oldPass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: passForm.oldPass,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => passForm.oldPass = $event),
            autocomplete: "off",
            type: "password",
            placeholder: _ctx.$t('password.hint_text.curr_password_hint'),
            "show-password": ""
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_form_item, {
          label: _ctx.$t('password.new_password'),
          prop: "newPass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: passForm.newPass,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => passForm.newPass = $event),
            autocomplete: "off",
            type: "password",
            placeholder: _ctx.$t('password.hint_text.new_password_hint'),
            "show-password": ""
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"]), _createVNode(_component_el_form_item, {
          label: _ctx.$t('password.confirm_password'),
          prop: "checkPass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: passForm.checkPass,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => passForm.checkPass = $event),
            autocomplete: "off",
            type: "password",
            placeholder: _ctx.$t('password.hint_text.confirm_password_hint'),
            "show-password": "",
            onKeydown: _withKeys(handleSavePasswordClick, ["enter"])
          }, null, 8, ["modelValue", "placeholder", "onKeydown"])]),
          _: 1
        }, 8, ["label"])]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: handleSavePasswordClick
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('password.save_btn')), 1)]),
        _: 1
      })])])]);
    };
  }

};