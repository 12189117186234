import request from '@/service/index'

const passwordApi = {
  editPassword: '/portal/setting/password'
}

export function editPassword(data) {
  return request.put({
    url: passwordApi.editPassword,
    data
  })
}
